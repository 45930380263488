import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/careers-hero.jpg';
import TwoCol from '../components/rows/twocol/index';

import Follow from '../components/rows/cta/follow';
import Cta from '../components/rows/cta/index';

export default function Careers({ data }) {
  return (
    <Layout>
      <Seo
        title="Careers"
        description="Join our team of PACE cardiologists, researchers, physicians, nurses, cardiac sonographers & technologists, 
          medical administrative professionals & more."
      />
      <Hero title="Careers" banner={Banner} />
      {data.allStrapiCareer.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <TwoCol
            key={node.career1.id}
            mailto
            title={node.career1.title}
            description={node.career1.description}
            link={node.career1.button_title}
            href={node.career1.button_link}
            image={node.career1.image?.localFile?.publicURL}
          />
          <Cta
            title="Learn More About PACE"
            button="About PACE"
            href="/about"
          />
          <TwoCol
            key={node.career2.id}
            reverse
            mailto
            title={node.career2.title}
            description={node.career2.description}
            link={node.career2.button_title}
            href={node.career2.button_link}
            image={node.career2.image?.localFile?.publicURL}
          />
        </React.Fragment>
      ))}
      <div className="mt-56">
        <Follow />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query Careers {
    allStrapiCareer {
      edges {
        node {
          id # id of the node
          career1 {
            id
            title
            description
            button_title
            button_link
            image {
              localFile {
                publicURL
              }
            }
          }
          career2 {
            id
            title
            description
            button_title
            button_link
            image {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
